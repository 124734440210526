import React, { useState, useContext } from "react";
import { CreateContext } from "../../context/createContext";
import PixilLogo from "../../assests/images/pixlip_logo.svg";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import "./scss/index.scss";

export const Header = () => {
  const { value, onChangeFunc } = useContext(CreateContext);
  const [anchorEl, setAnchorEl] = useState(null);
  //const [value, setValue] = useState("");
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    // this function will be executed after the component is mounted
    initialize();
  }, []);

  function initialize() {
    if(queryParams.get('lang',value)) {
      onChangeFunc(queryParams.get('lang',value));
    } else {
      queryParams.set('lang',value)
    }
   
  }

  const handleClose = (event) => {
    setAnchorEl(null);
    onChangeFunc(event.target.value)
  };
  return (
    <div className="w-full bg-white">
     <div className="flex justify-between items-center m-auto maxWidth h-12">
        <div>
          <a href="/" ><img src={PixilLogo} width="100px" height={"100px"} alt="pixilp" /></a>
        </div>
        <div>
          <a href="https://pixlip.com" className='bth'>
            <span className=''>
              {value === "de" ? "Zurück zur Homepage" : "Back to homepage"}
            </span>
            </a>
        </div>
        <div>
          <select
            id="basic-menu"
            open={open}
            onClose={handleClose}
            className="selection bg-white outline-none border-0 w-11"
            value={value}
            onChange={onChangeFunc}
          >
            <option onClick={handleClose} value="de">
              DE
            </option>
            <option onClick={handleClose} value="eng">
              EN
            </option>
            
          </select>
        </div>
  </div>
    </div>
  );
};
