import React, { useState, useContext, useEffect } from "react";
import { Btn, Input, Header, Footer } from "../../component/index";
import { useNavigate } from "react-router-dom";
import "./scss/index.scss";
import axios from "axios";
import { CreateContext } from "../../context/createContext";
import { useLocation } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Analytics } from '@vercel/analytics/react';

export const Authentication = (props) => {
  let { value } = useContext(CreateContext);
  const navigate = useNavigate();
  let [values, setValues] = useState();
  let [emails, setEmails] = useState("");
  const [alertField, setAlertField] = useState(null);
  const [alertAuftragNummer, setAlertAuftragNummer] = useState(null);
  const [alertEmail, setAlertEmail] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let lang = "de"
  async function handleClick() {
    // console.log(id);



    // var getToken = await axios.post(`https://order-dashboard-server2.herokuapp.com/erp/authentication/login`, {
    //   ordernumber: values,
    //   mail: emails,
    //   });



    //   if(getToken.data.accessToken){
    //     var getOrder = await axios.post(`https://order-dashboard-server2.herokuapp.com/erp/order/`, {
    //   ordernumber: values
    //   },{
    //     headers: {
    //     'Authorization': `Bearer ${getToken.data.accessToken}` 
    //     }});

    //     console.log("öh", getOrder);


    //     props.func(getOrder.data);

    //     navigate(`/pixil/${values}`);
    //   }
    var msg = "";
    setAlertField(msg)
    setAlertAuftragNummer(null);
    setAlertEmail(null);

    var somethingmissing = false;

    if (values == "" || values == null) {

      if (value == "de") {
        setAlertAuftragNummer("Auftragsnummer fehlt");
      } else {
        setAlertAuftragNummer("Ordernumber is missing");
      }
      somethingmissing = true;
    }
    if (emails == "" || emails == null) {
      if (value == "de") {
        setAlertEmail("E-Mail Adresse fehlt");
      } else {
        setAlertEmail("E-Mail is missing");
      }
      somethingmissing = true;
    }


    if (!somethingmissing) {
      try {
        var getOrder = await axios.get('https://api.pixlip.com:8843/tracking/auth', {
          params: {
            auftragsNummer: values,
            email: emails
          }
        })
        props.func(getOrder.data);

        //querylandfinden

        let languageParam;
        languageParam = "?lang=" + lang;
        if (languageParam == null) {
          languageParam = "?lang=de";
        }
        navigate(`/pixil/${values}${languageParam}`);
      }
      catch (e) {
        //rescode 402
        //console.log(e.response)
        if (e.response.status === 401) {
          msg = "";
          if (value == "de") {
            msg = "Nutzerdaten stimmen nicht überein. Gesuchte Mail: " + e.response.data;
          } else {
            msg = "Userdata doesn't match. Matching mail: " + e.response.data;
          }
          setAlertField(msg)
        }
        else if (e.response.status === 402) {
          msg = "";
          if (value == "de") {
            msg = "Der Auftrag wurde nicht gefunden";
          } else {
            msg = "The searched order was not found";
          }
          setAlertField(msg)
        } else {
          msg = "";
          if (value == "de") {
            msg = "Der Auftrag konnte nicht geladen werden";
          } else {
            msg = "The order could not be fetched";
          }
          setAlertField(msg)
        }
      }
    }
  };
  useEffect(() => {
    checkQuery();
  }, [checkQuery]);

  async function checkQuery() {
    try {
      let queryOrder = queryParams.get("order");
      let queryMail = queryParams.get("email");
      let queryLang = queryParams.get("lang");
      if (queryLang === 'de' || queryLang === 'eng') {
        lang = queryLang;
        value = lang;
      }
      if (queryOrder && queryMail) {
        //console.log("values", values)
        emails = queryMail;
        values = queryOrder;
        setValues(values);
        setEmails(emails);
        handleClick();
      }
    } catch (e) {
      console.log(e)
    }
  }


  return (
    <div>
      <Header />
      <div className="py-20">
        <Analytics />
        <div className="auth flex flex-col justify-center items-center xs:overflow-hidden w-full">
          <div className="card p-6 rounded-lg">
            <h1>{value == "de" ? "Finde deinen Auftrag" : "Find your Order"}</h1>
            <p className="text-sm text-center my-2">
              {value == "de" ? "Verfolgen Sie den Lieferstatus Ihrer Bestellung" : "Track the delivery status of your order"}
            </p>
            <div className="w-full py-3 my-2">
              <div className="input-email rounded">
                <Input
                  placeholder={value == "de" ? "Auftragsnummer" : "ordernumber"}
                  type="number"
                  name="ordernumber"
                  bgColor="#F5F7F8"
                  width="100%"
                  padding="8px 12px"
                  borderRadius="18px"
                  value={values}
                  onChange={(e) => setValues(e.target.value)}
                />
                {alertAuftragNummer && <div className="redwarning">{alertAuftragNummer}</div>}
              </div>
            </div>
            <div className="w-full py-2 my-2">
              <div className="input-password rounded">
                <Input
                  placeholder={value == "de" ? "Email" : "email"}
                  type="email"
                  name="email"
                  bgColor="#F5F7F8"
                  width="100%"
                  padding="8px 12px"
                  borderRadius="18px"
                  value={emails}
                  onChange={(e) => setEmails(e.target.value)}
                />
                {alertEmail && <div className="redwarning">{alertEmail}</div>}
              </div>
            </div>
            <div className="btn-sign w-full py-3 my-2">
              <div className=" rounded text-center">
                <Btn
                  value={value == "de" ? "Auftrag suchen" : "search order"}
                  bgColor="#0071E3"
                  type="button"
                  color="white"
                  width="100%"
                  padding="10px 0px"
                  borderRadius="18px"
                  onClick={handleClick}
                // disabled={values == 0 || emails == ""}
                />
              </div>
            </div>
            <div className="btn-sign w-full py-3 my-2">
              {alertField && <div className="redwarning">{alertField}</div>}
            </div>
            <div className="w-full py-2 my-2">
              <a
                href="#/"
                className="text-sm text-center flex justify-center"
                style={{ color: "#0071E3" }}
              >
                <Popup
                  trigger={<button className="button"> {value == "de" ? "Wo finde ich meine Auftragsnummer?" : "How to find your ordernumber"} </button>}
                  modal
                  nested
                >
                  {close => (
                    <div className="modal">
                      <button className="close" onClick={close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="35" viewBox="0 0 26 35" fill="none">
                          <path d="M23.1968 10.2949C24.0432 9.44043 24.0432 8.05273 23.1968 7.19824C22.3505 6.34375 20.976 6.34375 20.1297 7.19824L13 14.4033L5.86351 7.20508C5.01716 6.35059 3.64268 6.35059 2.79633 7.20508C1.94997 8.05957 1.94997 9.44727 2.79633 10.3018L9.93278 17.5L2.8031 24.7051C1.95674 25.5596 1.95674 26.9473 2.8031 27.8018C3.64945 28.6563 5.02393 28.6563 5.87028 27.8018L13 20.5967L20.1364 27.7949C20.9828 28.6494 22.3573 28.6494 23.2036 27.7949C24.05 26.9404 24.05 25.5527 23.2036 24.6982L16.0672 17.5L23.1968 10.2949Z" fill="black" />
                        </svg>
                      </button>
                      <div className="header"> Bei Shopbestellungen </div>
                      <div className="content">
                        {' '}
                        Melden Sie sich zunächst in Ihrem Account auf der Webseite des Onlineshops an.
                        Nach der erfolgreichen Anmeldung navigieren Sie zu Ihrem Konto-Bereich.
                        Innerhalb dieses Bereichs gibt es einen Abschnitt namens <a class="link" href="https://www.pixlip.com/account/order" target="_blank">"Bestellungen"</a>.<br></br>
                        Durch Anklicken dieses Abschnitts werden alle Ihre bisherigen Bestellungen inkl. Bestellnummer aufgeführt.

                      </div>
                      <div className="header"> Per E-Mail </div>
                      <div className="content">
                        {' '}
                        AAlternativ können Sie die Bestellung auch in der E-Mail-Bestätigung finden, die Sie nach der Bestellung erhalten haben.
                        Suchen Sie einfach in Ihrem E-Mail-Posteingang oder im Spam-Ordner nach einer E-Mail von @pixlip.com.
                        Diese E-Mail enthält alle Details Ihrer Bestellung, einschließlich der Bestellnummer.
                        Sollten Sie Ihre Bestellnummer immer noch nicht finden können, wenden Sie sich bitte an den Kundenservice.
                      </div>
                      <div className="actions">
                        <button
                          className="button-close"
                          onClick={() => {
                            close();
                          }}
                        >
                          {value == "de" ? "Schließen" : "Close"}
                        </button>
                      </div>
                    </div>
                  )}
                </Popup>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
