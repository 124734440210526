import React, { useState, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Btn, Header, Footer } from "../../component/index";
import { Hardware } from "./hardware";
import { Print } from "./print";
import "./scss/index.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CreateContext } from "../../context/createContext";
import env from "react-dotenv";
import { Analytics } from '@vercel/analytics/react';
import { useEffect } from "react";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid rgb(245,247,248)",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};

function parseAdress(stringAdress) {
  if (stringAdress) {
    var counter1 = 0;
    var adresslines = [];
    for (var z = 0; z < stringAdress.length; z++) {

      if (stringAdress.charAt(z) == '\n') {
        adresslines.push(stringAdress.substring(counter1, z));
        counter1 = z;
      }
    }

    return (adresslines);
  }
}



export const Dashboard = (props) => {
  const { value } = useContext(CreateContext);
  const [printvalue, setPrintValue] = useState("");
  const [hardwarevalue, setHardwareValue] = useState("");

  const [orderData, setOrderData] = useState(null);

  const [dataOrder, setDataOrder] = useState({});

  const [adressBillingLines, setAdressBillingLines] = useState(null);
  const [adressShippingLines, setAdressShippingLines] = useState(null);

  var [hardwarecounter2, setHardwareCounter] = useState(0);
  var [printcounter2, setPrintCounter] = useState(0);

  const navigate = useNavigate();

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);



  let { id } = useParams();
  // MODAL
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //  Radio
  const handleChangeValue = (event) => {
    setPrintValue(event.target.value);
  };
  const handleChangeValueHard = (event) => {
    setHardwareValue(event.target.value);
  };

  const refreshOrderData = async (data) => {

    try {
      var getData = await axios.get('https://api.pixlip.com:8843/tracking/data', {
        params: {
          orderNumber: data.data.ordernumber
        }
        });
      var customerAndOrder = getData.data
      var response = { data: {} }
      if (customerAndOrder.order && customerAndOrder.customer) {
        if (customerAndOrder.order.auftragsNummer == data.data.ordernumber && customerAndOrder.order.auftragsNummer == id && (data.data.mail.toLowerCase() == 'admin@pixlip.com' || data.data.mail.toLowerCase() == customerAndOrder.customer.eMail.toLowerCase() || data.data.mail.toLowerCase() == customerAndOrder.KundenMail.toLowerCase())) {
          if (customerAndOrder.order.individualFieldData) {
            customerAndOrder.order.individualFieldData.forEach(function (item) {
              if (item.definitionCode == "6280219") {
                response.data.hardwareInProduction = item.value;
              }
              if (item.definitionCode == "135157103") {
                response.data.hardwareInDelivery = item.value;
              }
              if (item.definitionCode == "840148006") {
                response.data.hardwareDelivered = item.value;
              }
              if (item.definitionCode == "1356852399") {
                response.data.hardwareTrackingNumber = item.value;
              }
              if (item.definitionCode == "1999034311") {
                response.data.hardwareEstimatedDeliveryDate = item.value;
              }
              if (item.definitionCode == "55432894") {
                response.data.printDataStatus = item.value;
              }
              if (item.definitionCode == "37162448") {
                response.data.printProductionStatus = item.value;
              }
              if (item.definitionCode == "1633485620") {
                response.data.printTrackingNumber = item.value;
              }
              if (item.definitionCode == "70715909") {
                response.data.printEstimatedDeliveryDate = item.value;
              }
              if (item.definitionCode == "1378218261") {
                response.data.printDeliveryMethod = item.value;
              }
              if (item.definitionCode == "320532404") {
                response.data.hardwareDeliveryMethod = item.value;
              }
              if (item.definitionCode == "992458165") {
                response.data.shipmentProviderHardware = item.value;
              }
              if (item.definitionCode == "70055514") {
                response.data.shipmentProviderPrints = item.value;
              }
              if (item.definitionCode == "1900329112") {
                response.data.printDataReceived = item.value;
              }
              if (item.definitionCode == "13016945") {
                response.data.orderHasPrints = item.value;
              }
            });

            response.data.positions = [];
            customerAndOrder.order.positionen.forEach(function (item) {
              if (item.anzahl > 0 && !item.langtext.includes("ersand") && !item.kurztext.includes("paket") && !item.kurztext.includes("Paket") && item.einheit != "m²") {
                response.data.positions.push(item);
              }
            });



            response.data.hardwarecounter = 0;
            response.data.printcounter = 0;
            response.data.whitecounter = 0;
            response.data.positions.forEach(async function (item) {
              var productGet = await axios.get('https://api.pixlip.com:8843/tracking/product', {
                params: {
                  artikelCode: item.artikelCode,
                }
                })
                if(productGet && productGet.data.individualFieldData){
              productGet.data.individualFieldData.forEach(function (item2) {

                if (item2.definitionCode == '721945605') {
                  if (item2.value == true) {
                    item.druckartikel = true;
                    setPrintCounter(++printcounter2);
                    response.data.printcounter++;
                  } else {
                    item.druckartikel = false;
                    setHardwareCounter(++hardwarecounter2);
                    response.data.hardwarecounter++;

                  }

                }
                if (item2.definitionCode == '1750388807') {
                  if (item2.value == true) {
                    response.data.whitecounter++;

                  }
                }

              })}
            });

            response.data.shippingAdress = customerAndOrder.order.nebenadresse1Text;
            response.data.billingAdress = customerAndOrder.order.nebenadresse2Text;
            response.data.ordernumber = data.data.ordernumber;
            response.data.mail = data.data.mail;
            response.data.auftragsbeginn = customerAndOrder.order.auftragsbeginn;
            setOrderData(response);

            setAdressBillingLines(parseAdress(response.data.billingAdress));
            setAdressShippingLines(parseAdress(response.data.shippingAdress));



          }


        } else {
          navigate(`/`);
        }
      }
    } catch (e) {
      alert("Lost connection")
      navigate(`/`);
    }
  }


  useEffect(() => {
    if (props.data.billingAdress != "") {
      setOrderData(props);
      localStorage.setItem('sessionData', JSON.stringify(props))


      setAdressBillingLines(parseAdress(props.data.shippingAdress));
      setAdressShippingLines(parseAdress(props.data.billingAdress));

      var storage = JSON.parse(localStorage.getItem('sessionData'));

      refreshOrderData(storage)
    } else {
      let storage = JSON.parse(localStorage.getItem('sessionData'));
      if (storage) {

        refreshOrderData(storage);


      } else {
        navigate(`/`);
      }
    }



  }, []);




  return (
    <div>
      <Header />

      <div className="py-10 h-fit overflow-auto">
        <Analytics />
        <div className="mx-auto maxWidth mt-7 dashboard">
          <div className="ordernumber flex justify-end items-center">
            <span>{value == "de" ? "Auftrag" : "Order"}</span>
            <h1>{id && id}</h1>

          </div>
          {/* three cards */}
          <div className="Main_card">
            {/* card One Description */}
            <div className="bg-white   card-one">
              <div className="p-6">
                <h1>{value == "de" ? "Lieferadresse" : "Delivery address"}</h1>
                {adressShippingLines &&
                  adressShippingLines.map((item) => (
                    <p>{item}</p>
                  ))}
                {/* <p>
                <span>{value == "de" ? "Straße" : "Street"}</span> Augsburget StraBe 39
              </p>
              <p>
                {" "}
                <span>{value == "de" ? "Stadt" : "City"}</span> Unna
              </p>
              <p>
                {" "}
                <span>{value == "de" ? "Tel." : "Phone"}</span> 02303367228
              </p>
              <p>
                {" "}
                <span>{value == "de" ? "PLZ" : "ZIP Code"}</span> 59423
              </p>
              <p>
                {" "}
                <span>{value == "de" ? "Land" : "Country"}</span> Germany
              </p> */}
              </div>
              <div className="pl-6 pt-5">
                <h1>{value == "de" ? "Rechnungsadresse" : "Invoice address"}</h1>

                {adressBillingLines &&
                  adressBillingLines.map((item) => (
                    <p>{item}</p>
                  ))}
                {/* <p>
                <span>{value == "de" ? "Straße" : "Street"}</span> Augsburget StraBe 39
              </p>
              <p>
                {" "}
                <span>{value == "de" ? "Stadt" : "City"}</span> Unna
              </p>
              <p>
                {" "}
                <span>{value == "de" ? "Tel." : "Phone"}</span> 02303367228
              </p>
              <p>
                {" "}
                <span>{value == "de" ? "PLZ" : "ZIP Code"}</span> 59423
              </p>
              <p>
                {" "}
                <span>{value == "de" ? "Land" : "Country"}</span> Germany
              </p> */}
              </div>

              {/* versandart 

            <div className="pl-6 pt-5">
              <h1>{value == "de" ? "Versandart" : "Shipping Method"}</h1>
              <h3 className="text-lg pl-1 pt-3">Hardware</h3>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={hardwarevalue}
                  onChange={handleChangeValueHard}
                >
                  <div className="flex  items-center ">
                    <FormControlLabel
                      value="standeredShippinghardware"
                      control={<Radio />}
                    />
                    <p>Standered Shipping</p>
                  </div>
                  <div className="flex  items-center ">
                    <FormControlLabel
                      value="expressshippinghardware"
                      control={<Radio />}
                    />
                    <p>Express Shipping (+100$)</p>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>

            */}

              {/* print 

            <div className="pl-6 pt-3">
              <h3 className="text-lg pl-1">Print</h3>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={printvalue}
                  onChange={handleChangeValue}
                >
                  <div className="flex  items-center ">
                    <FormControlLabel
                      value="standeredShippingprint"
                      control={<Radio />}
                    />
                    <p>Standered Shipping</p>
                  </div>
                  <div className="flex  items-center ">
                    <FormControlLabel
                      value="expressshippingprint"
                      control={<Radio />}
                    />
                    <p>Express Shipping (+100$)</p>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            <div className="p-6">
              <Btn
                value="versandart andern"
                bgColor="rgb(0,113,227)"
                type="button"
                color="white"
                width="100%"
                padding="10px 0px"
                borderRadius="18px"
                onClick={handleOpen}
              />
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <div className="relative">
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                      className="text-center"
                    >
                      Change delivery method
                    </Typography>
                    <div className="absolute -top-9 -right-8 ">
                      <Button
                        onClick={handleClose}
                        className="font-extrabold text-5xl"
                      >
                        <CloseIcon sx={{ fontSize: 40, color: "black" }} />
                      </Button>
                    </div>
                  </div>
                  <div className="list_data  my-4">
                    <span className="flex justify-between">
                      <h2>Article</h2>
                      <h2>Price</h2>
                    </span>
                    <span className="flex justify-between">
                      <p>Express Shipping hardware</p>
                      <p>100$</p>
                    </span>
                    <span className="flex justify-between">
                      <p>Express Shipping prints</p>
                      <p>100$</p>
                    </span>
                    <span className="flex justify-between">
                      <h2>Total</h2>
                      <h2>200$</h2>
                    </span>
                  </div>
                  <div className="payment">
                    <Btn
                      value="PayPal"
                      width="100%"
                      bgColor="#FFC34C"
                      padding="10px 0px"
                      color="black"
                    />
                    <Btn
                      value="SEPA"
                      width="100%"
                      bgColor="#EEEDEE"
                      padding="10px 0px"
                      color="black"
                    />
                    <Btn
                      value="giroPay"
                      width="100%"
                      bgColor="#E1E1E2"
                      padding="10px 0px"
                      color="black"
                    />
                    <Btn
                      value="sofat"
                      width="100%"
                      bgColor="#E1E1E2"
                      padding="10px 0px"
                      color="black"
                    />
                    <Btn
                      value="Debit- oder Kreditkarte"
                      width="100%"
                      bgColor="#2D2E2F"
                      padding="10px 0px"
                      color="white"
                    />
                  </div>
                </Box>
              </Fade>
            </Modal>*/}
            </div>

            <div className="bg-white card_two">
              {orderData &&
                <Hardware data={orderData} />
              }
            </div>
            <div className="bg-white card_three">
              {orderData &&
                <Print data={orderData} />
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
