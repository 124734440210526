import "./scss/index.scss";
import PixilLogo from "../../assests/images/pixlip_logo.svg";
import React, { useState, useContext } from "react";
import { CreateContext } from "../../context/createContext";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
export const Footer = () => {
  const { value, onChangeFunc } = React.useContext(CreateContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    // this function will be executed after the component is mounted
    initialize();
  }, []);

  function initialize() {
    if (queryParams.get('lang', value)) {
      onChangeFunc(queryParams.get('lang', value));
    } else {
      queryParams.set('lang', value)
    }

  }
  return (
    <div className="footer-main">
      <div className="container">
        <div id="footerColumns" className="row footer-columns" data-collapse-footer="true" role="list">
          <div className="col-md-4 footer-column is-logo-column">
            <div className="footer-column-headline footer-headline">
              <div className="logo-container">
                <img src={PixilLogo} alt="pixilp" className="img-fluid header-logo-main-img" />
              </div>
            </div>

            <div className="footer-column-content footer-contact">
              <div className="footer-column-content-inner">
                <p className="footer-company-info">
                  {value === "de" ? "Hochwertige LED-Leuchtsystemlösungen für Messebau, Ladenbau und mobile Präsentationen. PIXLIP ist Weltmarktführer auf dem Gebiet der LED-Werbeflächen." : "High-quality LED lighting system solutions for trade fair construction, shop fitting and mobile presentations. PIXLIP is the world market leader in the field of LED advertising space."}
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-2 footer-column">
            <div className="footer-column-headline footer-headline">
              {value === "de" ? "Wissensdatenbank" : "Knowledge Base"}
            </div>

            <div className="footer-column-content">
              <div className="footer-column-content-inner">
                <ul className="list-unstyled">
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Service/Tutorials-Webinare/Lichtwissen/" title="Lichtwissen">
                      {value === "de" ? "Lichtwissen" : "Light knowledge"}
                    </a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/service/Tutorials-Webinare/Druckdaten/" title="Druckdaten">
                      {value === "de" ? "Druckdaten" : "Print Data"}
                    </a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Service/Downloadcenter/" title="Downloadcenter">
                      {value === "de" ? "Downloadcenter" : "Download Center"}
                    </a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Service/#faq" title="FAQ's">
                      FAQ's
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-2 footer-column">
            <div className="footer-column-headline footer-headline">
              {value === "de" ? "Informationen" : "Informations"}
            </div>

            <div className="footer-column-content">
              <div className="footer-column-content-inner">
                <ul className="list-unstyled">
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Versand-Zahlung" title="Versand &amp; Zahlung">
                      {value === "de" ? "Versand & Zahlung" : "Shipment & Payment"}
                    </a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Kontakt" title="Kontakt">
                      {value === "de" ? "Kontakt" : "Contact"}
                    </a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Impressum" title="Impressum">
                      {value === "de" ? "Impressum" : "Legal Notice"}
                    </a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Datenschutz" title="Datenschutz">
                      {value === "de" ? "Datenschutz" : "Privacy policy"}
                    </a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Footer-Menu/Informationen/Reklamation/" title="Reklamation">
                      {value === "de" ? "Reklamation" : "Refund Policy"}
                    </a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Service/Shop-Service/AGB/" title="AGB">
                      {value === "de" ? "AGB" : "Terms of Service"}
                    </a>
                  </li>

                  <li className="footer-link-item">
                    <a className="footer-link" href="https://www.pixlip.com/Footer-Menu/Informationen/Widerrufsbelehrung/" title="Widerrufsbelehrung">
                      {value === "de" ? "Widerrufsbelehrung" : ""}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-2 footer-column">
            <div className="footer-column-headline footer-headline">
              {value === "de" ? "Kontakt" : "Contact"}
            </div>

            <div className="footer-column-content">
              <div className="footer-column-content-inner">
                <div className="mobile-number is-contact-link">
                  <i className="fas fa-phone-alt" aria-hidden="true"></i>
                  <a href="tel:+49 (0) 2173204580" className="contact-link">+49 (0) 2173204580</a>
                </div>
                <div className="email-address is-contact-link">
                  <i className="fas fa-envelope" aria-hidden="true"></i>
                  <a href="mailto:info@pixlip.com" className="contact-link">info@pixlip.com</a>
                </div>
                <div className="location is-contact-link">
                  <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  <span className="contact-link">
                    Otto-Hahn-Straße 11, 40721 Hilden <br />{value === "de" ? "Deutschland" : "Germany"}
                  </span>
                </div>
                <div className="pixlip-socials">

                  <a target="_blank" href="https://www.facebook.com/pixlip/" className="social-link">
                    <i className="fab fa-facebook" aria-hidden="true"></i>
                  </a>

                  <a target="_blank" href="https://www.pinterest.de/lightwallsystem/" className="social-link">
                    <i className="fab fa-pinterest" aria-hidden="true"></i>
                  </a>

                  <a target="_blank" href="https://de.linkedin.com/company/pixlip" className="social-link">
                    <i className="fab fa-linkedin" aria-hidden="true"></i>
                  </a>

                  <a href="https://www.instagram.com/pixlip/" target="_blank" className="social-link">
                    <i className="fab fa-instagram" aria-hidden="true"></i>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pixlip-footer-bottom d-flex justify-content-space-between flex-wrap flex-column-reverse flex-lg-row">
          <div className="copyright-column col-xs-12 my-4 col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-start">
            © Copyright 2023 Pixlip®
          </div>
          <div className="footer-logos col-xs-12 my-4 col-lg-6">

          </div>
          <div className="spacer col-xs-12 col-lg-3"></div>
        </div>

      </div>
    </div>

  );
};