import React, { useEffect, useRef, useState, useContext } from "react";
import "./scss/index.scss";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { CreateContext } from "../../../context/createContext";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import tntLogo from "../../../assests/images/tnt-3.svg";
import upsLogo from "../../../assests/images/ups.svg";

import Check from "@mui/icons-material/Check";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#55C85A",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#55C85A",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#55C85A",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#55C85A",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

var steps = [
  "Order received",
  "Printdata received",
  "In production",
  "In delivery"
];
const initialData = [/*
  {
    id: 1,
    title: "GO LIGHTBOX 100200",
    quantity: "2x",
    articleNumber: "156002",
    dimesionNumber: "100cm x 200cm",
  },
  {
    id: 2,
    title: "POP LIGHTBOX",
    quantity: "3x",
    articleNumber: "156002",
    dimesionNumber: "100cm x 200cm",
  },*/
];

// on Error Data Show
const DataError = [
  {
    msg: "Print-data not received",
    link: "Upload now",
    description:
      "If you have already sent your print Data you do not need to do anything. It can take up to 48 hours until they are processed",
    develiverTime: "",
    snipmentprovider: "",
    TrackingNumber: "",
  },
];
const WarningData = [
  {
    msg: "Printing data need correction",
    link: "Upload now",
    description:
      "If you have already sent your print Data you do not need to do anything. It can take up to 48 hours until they are processed",
    develiverTime: "",
    snipmentprovider: "",
    TrackingNumber: "",
  },
];

export const Print = (props) => {
  const { value } = useContext(CreateContext);
  const [data, setData] = useState(initialData);
  const [showError, setShowError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [dataerror, setDataError] = useState(DataError);
  const [check, setCheck] = useState(false);
  const [newDate, setNewDate] = useState(null);

  var [hardwarecounter, setHardwareCounter] = useState(0);
  var [printcounter, setPrintCounter] = useState(0);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const [trackingArray, setNewTrackingArray] = useState(null);
  const [trackingSet, setTrackingSet] = useState(false);

  const [newTrackingArrayFinal, setNewTrackingArrayFinal] = useState([]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps();
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step) => (e) => {
    if (e.target.innerHTML == 5 && activeStep >= 4) {
      setActiveStep(step++);
    }
    setActiveStep(step);
  };


  const isStepFailed = (step) => {
    if (showError === "success") {
      return step === 9;
    }
    return step === 1;
  };


  useEffect(() => {
   
    //console.log(props.data.data.auftragsbeginn ? props.data.data.auftragsbeginn  : props.data.data.printEstimatedDeliveryDate)
    var date123 = new Date(props.data.data.auftragsbeginn ? props.data.data.auftragsbeginn  : props.data.data.printEstimatedDeliveryDate)
   
    setNewDate(date123);

    if(props.data.data.printDataReceived == true){
      setActiveStep(1);
    }

    if(props.data.data.printDataReceived == false ){
      setActiveStep(1);
      setShowWarning(true);
    }
    if(props.data.data.printDataStatus == "Bestellt"){
      setActiveStep(2);
    }
    if(props.data.data.printProductionStatus == "In Produktion"){
      setActiveStep(2);
    }
    if(props.data.data.printProductionStatus == "Versendet"){
      setActiveStep(3);
    }
    if(props.data.data.printProductionStatus == "Geliefert"){
      setActiveStep(3);
    }
    
    if(props.data.data.printDataStatus == "Korrektur"){
      setActiveStep(1);
      setShowError(true);
      //setShowWarning(true);
    }
    if(props.data.data.printTrackingNumber) {
      setActiveStep(4)
    }


    
  }, []);

  useEffect(()=>{
    if(!props.data.data.printTrackingNumber) {
      props.data.data.printTrackingNumber = ''
    }
    var newTrackingArray = props.data.data.printTrackingNumber.split(";");
    var newTrackingArrayFinal = [];
    newTrackingArray.forEach(function(item){
        var newJSONTracking = {
            provider: item.split(",")[0],
            trackingnumber: item.split(",")[1]
        }
        if(newJSONTracking.provider != "" && newJSONTracking.provider != null){
            newTrackingArrayFinal.push(newJSONTracking);
        }
    });
    setNewTrackingArrayFinal(newTrackingArrayFinal)
   
  },[props.data.data.hardwareTrackingNumber])

  if(value == "de"){
    steps = ["Auftrag eingegangen","Druckdaten eingegangen", "In Produktion", "Versandt"]
  }else{
    steps = [ "Order received",
    "Printdata received",
    "In production",
    "Sent"
    ];
  }

let EstimatedDeliveryDate;
  if(newDate){
    
    EstimatedDeliveryDate = <p>{newDate.getDate()}.{newDate.getMonth()+1}.{newDate.getFullYear()}</p>;
  }else{
    EstimatedDeliveryDate = <p></p>
  }

  return (
    <div className="hardware px-6 py-8 ">
      <h1>Print</h1>
      {props.data.data.printcounter > 0 ? (
      <div
        className={
          showWarning == true
            ? "steppers warningClass"
            : showError == true
            ? "steppers failedClass"
            : "steppers"
        }
      >
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            connector={<QontoConnector />}
            activeStep={activeStep }
          >
            {steps.map((label, index) => {
              const labelProps = {};
              if(showWarning && index==1 && (props.data.data.printcounter != props.data.data.whitecounter) && activeStep<2){labelProps.error = true;}
           
              return (
                <Step key={label} completed={completed[index]}>
                  <StepLabel {...labelProps} >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Stack>

        <div  className="contents py-4 accordin-css">
          {(showWarning && (props.data.data.printcounter != props.data.data.whitecounter) && activeStep<2 && !showError) && <div>
                <div className="text-red-600 cursor-pointer">
                {value == "de" ? "Druckdaten wurden noch nicht hochgeladen" : "Printdata not received yet"}         
                </div>
                <div >
                <a className="trackNumber " target="_blank" href="https://upload.pixlip.com/"> {value == "de" ? "Jetzt hochladen" : "upload print data"}</a>

                </div>
                <div >
                {value == "de" ? "Falls Sie Ihre Druckdaten bereits gesendet haben brauchen Sie nichts weiter zu tun. Es kann bis zu 48 Stunden dauern bis sie bearbeitet werden." : "If you have already sent your print data you do not need to do anything. It can take up to 48 hours until they are processed."}
                </div>
                </div>
                }
                {showError && <p>
                  
                <div className="text-red-600 cursor-pointer">
                {value == "de" ? "Druckdaten benötigen Korrektur" : "Printdata not correct"}         
                </div>
                 <div>
                 <a className="trackNumber " target="_blank" href="https://upload.pixlip.com/"> {value == "de" ? "Jetzt erneut hochladen" : "Upload new print data"}</a>
                 </div>
                
                <div >
                {value == "de" ? "Falls Sie Ihre Druckdaten bereits gesendet haben brauchen Sie nichts weiter zu tun. Es kann bis zu 48 Stunden dauern bis sie bearbeitet werden." : "If you have already sent your print data you do not need to do anything. It can take up to 48 hours until they are processed."}
               
                </div>
                </p>
                }
                {/* <div>
                  <p className="textGreyy">{value == "de" ? "Vorraussichtliches Lieferdatum" : "Estimated delivery date"}</p>
                  {EstimatedDeliveryDate}
                </div> */}

                <div>
                  <p className="textGreyy">{value == "de" ? "Paketverfolgungsnummer" : "Tracking number"}</p>
                  {newTrackingArrayFinal && (
              <p>   
               
               {newTrackingArrayFinal.map((item) => {
    let element;
    if(item.provider == "UPS"){
        element = <div className="divTrackingLink"><img className="logoTracking" src={upsLogo} width="40px" height={"40px"} alt="upsLogo" /><a className="linkTracking" target="_blank" href={`https://www.ups.com/track?tracknum=${item.trackingnumber}`} key={item.trackingnumber}>{item.trackingnumber}</a><br></br></div>
    }else if(item.provider == "TNT"){
        element = <div className="divTrackingLink"><img className="logoTracking" src={tntLogo} width="40px" height={"40px"} alt="tntLogo" /><a className="linkTracking" target="_blank" href={`https://www.tnt.com/express/de_de/site/home/applications/tracking.html?searchType=CON&cons=${item.trackingnumber}`} key={item.trackingnumber}>{item.trackingnumber}</a><br></br></div>
    }else{
        element = <div key={item.trackingnumber}><p>test{item.provider}{item.trackingnumber}</p><br></br></div>
    }
    return element;
})}
             </p>
            )}
                </div>
              </div>
              

       
      </div>
      ): (
      <div className="noPrintsText">{value == "de" ? "In diesem Auftrag sind keine Drucke enthalten" : "This order does not contain any prints"}  </div>
      )}
      {/* Nested cards */}
      {props.data.data.positions && (
        <div>
          {props.data.data.positions.map((value) => {
            return (
              <div>
              {value.druckartikel == true && (
              <div className="nested_card" key={value.code}>
                <div className="justify-between p-3 ">
                <h2 className="produktName">{value.kurztext}</h2>
                  <h2>{value == "de" ? "Anzahl" : "Quantity"}: {value.anzahl}</h2>
                </div>
                <p>Article number: {value.artikelNummer}</p>
                {/*<p>Dimensions: {value.langtext}</p>*/}
              </div>
              )}
              </div>
            );
          })}
      </div>
      )}
    

      <Accordion className="accordins">
        <AccordionDetails>
        {props.data.data.positions && (
          <div>
          {props.data.data.positions.map((value) => {
            return (
              <div>
              {value.druckartikel == true &&(
              <div
                className={`${check == false ? "accor_drop" : "acc-active"}`}
                key={value.code}
              >
                <div className={`flex justify-between p-3 flex-wrap`}>
                  <h2 className="produktName">{value.kurztext}</h2>
                  <h2>{value.anzahl}</h2>
                </div>
                <p>Article number: {value.artikelNummer}</p>
                {/*<p>Dimensions: {value.langtext}</p>*/}
              </div>
              )}
              </div>
            );
          })}
          </div>
        )}
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setCheck(!check)}
            style={{ outline: "1px solid white" }}
            className="buttondrop"
          >
            <button
              className="arrowButton text-center w-full"
              onClick={() => setCheck(!check)}
            ></button>
          </AccordionSummary>
        </AccordionDetails>
      </Accordion>
    </div>
    
  );
};
