import "./App.scss";
import { Authentication, Dashboard } from "./modules";

import { Routes, Route } from "react-router-dom";
import { Usecontext } from "./context/usecontext";
import { render } from "@testing-library/react";
import React from "react";
class App extends React.Component {

  state = {
    billingAdress: "",
    hardwareDelivered: false,
    hardwareDeliveryMethod: "",
    hardwareEstimatedDeliveryDate: null,
    hardwareInDelivery: false,
    hardwareInProduction: false,
    hardwareTrackingNumber: "",
    printDataStatus: "",
    printDeliveryMethod: "",
    printEstimatedDeliveryDate: null,
    printProductionStatus: "",
    printTrackingNumber: "",
    shipmentProviderHardware: "",
    shipmentProviderPrints: "",
    shippingAdress: "",
    ordernumber: "",
    mail: ""
  }

  pull_data = (data) => {
    this.setState({
      billingAdress: data.billingAdress,
      hardwareDelivered: data.hardwareDelivered,
      hardwareDeliveryMethod: data.hardwareDeliveryMethod,
      hardwareEstimatedDeliveryDate: data.hardwareEstimatedDeliveryDate,
      hardwareInDelivery: data.hardwareInDelivery,
      hardwareInProduction: data.hardwareInProduction,
      hardwareTrackingNumber: data.hardwareTrackingNumber,
      printDataStatus: data.printDataStatus,
      printDeliveryMethod: data.printDeliveryMethod,
      printEstimatedDeliveryDate: data.printEstimatedDeliveryDate,
      printProductionStatus: data.printProductionStatus,
      printTrackingNumber: data.printTrackingNumber,
      shipmentProviderHardware: data.shipmentProviderHardware,
      shipmentProviderPrints: data.shipmentProviderPrints,
      shippingAdress: data.shippingAdress,
      printDataReceived: data.printDataReceived,
      ordernumber: data.ordernumber,
      mail: data.mail
    });
  }

  render() {


    return (
      <div>
        <div className="main-section">
          <Usecontext>
            <Routes>
              <Route path="/" element={<Authentication func={this.pull_data} />} />
              <Route path="/pixil/:id" element={<Dashboard data={this.state} />} />
            </Routes>
          </Usecontext>
          {/* <Dashboard /> */}
        </div>
      </div>
    );
  }
}

export default App;
