import React, { useState, useContext, useRef, useEffect } from "react";
import "./scss/index.scss";
import tntLogo from "../../../assests/images/tnt-3.svg";
import upsLogo from "../../../assests/images/ups.svg";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
import { CreateContext } from "../../../context/createContext";
import StepLabel from "@mui/material/StepLabel";
// import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import Check from "@mui/icons-material/Check";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#55C85A",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#55C85A",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#55C85A",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#55C85A",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

var steps = ["Order received", "In production", "In delivery", "Delivered"];


const initialData = [/*
  {
    id: 1,
    title: "GO LIGHTBOX 100200",
    quantity: "2x",
    articleNumber: "156002",
    dimesionNumber: "100cm x 200cm",
  },
  {
    id: 2,
    title: "POP LIGHTBOX",
    quantity: "3x",
    articleNumber: "156002",
    dimesionNumber: "100cm x 200cm",
  },
  {
    id: 3,
    title: "POP LIGHTBOX",
    quantity: "3x",
    articleNumber: "156002",
    dimesionNumber: "100cm x 200cm",
  },*/
];

export const Hardware = (props) => {
  const { value } = useContext(CreateContext);


  const [data, setData] = useState(initialData);
  const [check, setCheck] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const [newDate, setNewDate] = useState(null);

  const [trackingArray, setNewTrackingArray] = useState(null);
  const [trackingSet, setTrackingSet] = useState(false);

  const [newTrackingArrayFinal, setNewTrackingArrayFinal] = useState([]);

  const refDat = useRef(null);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps();
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step) => (e) => {
    if (e.target.innerHTML == 4 && activeStep >= 3) {
      setActiveStep(step++);
    }
    setActiveStep(step);
  };

  useEffect(() => {
    //console.log(props.data.data.auftragsbeginn ? props.data.data.auftragsbeginn  : props.data.data.hardwareEstimatedDeliveryDate)
   var date123 = new Date(props.data.data.auftragsbeginn ? props.data.data.auftragsbeginn  : props.data.data.hardwareEstimatedDeliveryDate)

    setNewDate(date123);


  //props.data.data.hardwareTrackingNumber = props.data.data.hardwareTrackingNumber.substring(0, props.data.data.hardwareTrackingNumber.length(-9));


    if(props.data.data.hardwareInProduction){
      setActiveStep(1);
    }
    if(props.data.data.hardwareInDelivery){
      setActiveStep(2);
    }
    if(props.data.data.hardwareDelivered){
      setActiveStep(4);
    }
    if(props.data.data.hardwareTrackingNumber) {
      setActiveStep(4)
    }




  }, []);

  if(value == "de"){
    steps = ["Auftrag eingegangen", "In Produktion", "Versandt"]
  }else{
    steps = ["Order received", "In production", "Sent"];
  }

//   var newTrackingArray =  props.data.data.hardwareTrackingNumber.split(";");;

//   var newTrackingArrayFinal = [];
//   newTrackingArray.forEach(function(item){
//     var newJSONTracking = {
//       provider: item.split(",")[0],
//       trackingnumber: item.split(",")[1]
//     }
//     if(newJSONTracking.provider != "" && newJSONTracking.provider != null){
//     newTrackingArrayFinal.push(newJSONTracking);
//   }
//   });

//   if(trackingSet == false && newTrackingArrayFinal != [] && trackingArray != null){
//   setNewTrackingArray(newTrackingArrayFinal);
//   setTrackingSet(true)
// }
  let EstimatedDeliveryDate;

if(newDate){

  EstimatedDeliveryDate = <p>{newDate.getDate()}.{newDate.getMonth()+1}.{newDate.getFullYear()}</p>;
}else{
  EstimatedDeliveryDate = <p></p>
}




useEffect(()=>{
  if(!props.data.data.hardwareTrackingNumber) {
    props.data.data.hardwareTrackingNumber = ''
  }
  console.log(props.data.data)
  var newTrackingArray = props.data.data.hardwareTrackingNumber.split(";");
  var newTrackingArrayFinal = [];
  newTrackingArray.forEach(function(item){
      var newJSONTracking = {
          provider: item.split(",")[0],
          trackingnumber: item.split(",")[1]
      }
      if(newJSONTracking.provider != "" && newJSONTracking.provider != null){
          newTrackingArrayFinal.push(newJSONTracking);
      }
  });
  setNewTrackingArrayFinal(newTrackingArrayFinal)

},[props.data.data.hardwareTrackingNumber])

  return (
    <div className="hardware px-6 py-8">
      <h1>Hardware</h1>
      {props.data.data.hardwarecounter > 0 ? (
      <div className="steppers">
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            connector={<QontoConnector />}
            activeStep={activeStep}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel >{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>

        <div className="contents accordin-css">
          {/* <div>
            <p className="textGreyy">{value == "de" ? "Vorraussichtliches Lieferdatum" : "Estimated delivery date"}</p>
            {EstimatedDeliveryDate}
          </div> */}
          <div>
            <p className="textGreyy">{value == "de" ? "Paketverfolgungsnummer" : "Tracking number"}</p>

              {/*<a className="" href="#" >{props.data.data.hardwareTrackingNumber}</a>*/}

                {newTrackingArrayFinal && (
              <p>

               {newTrackingArrayFinal.map((item) => {
    let element;
    if(item.provider == "UPS"){
        element = <div className="divTrackingLink"><img className="logoTracking" src={upsLogo} width="40px" height={"40px"} alt="upsLogo" /><a className="linkTracking" target="_blank" href={`https://www.ups.com/track?tracknum=${item.trackingnumber}`} key={item.trackingnumber}>{item.trackingnumber}</a><br></br></div>
    }else if(item.provider == "TNT"){
        element = <div><img className="logoTracking" src={tntLogo} width="40px" height={"40px"} alt="tntLogo" /><a className="linkTracking" target="_blank" href={`https://www.tnt.com/express/de_de/site/home/applications/tracking.html?searchType=CON&cons=${item.trackingnumber}`} key={item.trackingnumber}>{item.trackingnumber}</a><br></br></div>
    }else{
        element = <div key={item.trackingnumber}><p>test{item.provider}{item.trackingnumber}</p><br></br></div>
    }
    return element;
})}
             </p>
            )}


          </div>
        </div>
      </div>
      ): (
        <div className="noPrintsText">{value == "de" ? "In diesem Auftrag ist keine Hardware enthalten" : "This order does not contain any hardware products"}  </div>
        )}
        {props.data.data.positions && (
          <div>
      {props.data.data.positions.map((value) => {
        return (
          <div>
          {value.druckartikel == false && (
            <div className="nested_card" key={value.code}>
              <div className="justify-between p-3 ">
              <h2 className="produktName">{value.kurztext}</h2>
                <h2>{value == "de" ? "Anzahl" : "Quantity"}: {value.anzahl}</h2>
              </div>
              <p>Article number: {value.artikelNummer} </p>
              {/*<p>Dimensions: {value.langtext}</p>*/}
            </div>
            )}
            </div>
        );
      })}
      </div>
        )}
      {/* <Accordion className="accordins">
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ outline: "1px solid white" }}
          expandIcon={<ExpandMoreIcon />}
        >
          <button className="arrowButton text-center w-full">
          </button>
        </AccordionSummary>
        <AccordionDetails>
          {data.map((value) => {
            return (
              <div className="accor_drop" key={value.id}>
                <div className="flex justify-between p-3 flex-wrap">
                  <h1>{value.title}</h1>
                  <h2>{value.quantity}</h2>
                </div>
                <p>Article number: {value.articleNumber}</p>
                <p>Dimensions: {value.dimesionNumber}</p>
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion> */}

      <Accordion className="accordins">
        <AccordionDetails>
        {props.data.data.positions && (
          <div>
        {props.data.data.positions.map((value) => {
            return (
              <div>
              {value.druckartikel == false &&(
              <div
                className={`${check == false ? "accor_drop" : "acc-active"}`}
                key={value.code}
              >
                <div className={`flex justify-between p-3 flex-wrap`}>
                  <h2 className="produktName">{value.kurztext}</h2>
                  <h2>{value.anzahl}</h2>
                </div>
                <p>Article number: {value.artikelNummer}</p>
                {/*<p>Dimensions: {value.langtext}</p>*/}
              </div>
              )}
              </div>
            );
          })}
          </div>)}
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setCheck(!check)}
            style={{ outline: "1px solid white" }}
            className="buttondrop"
          >
            <button
              className="arrowButton text-center w-full"
              onClick={() => setCheck(!check)}
            ></button>
          </AccordionSummary>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
